<template>
  <v-dialog v-model="dialog" max-width="1600" scrollable>
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        class="mr-4 mt-1 white--text"
        color="xbColor"
        depressed
        v-bind="attrs"
        v-on="on"
      >
        Usuários Ali
      </v-btn>
    </template>

    <v-card :loading="loading" :disabled="loading">
      <v-card-title class="accent">
        Usuários a Classificar
        <v-spacer></v-spacer>
        <v-icon dark @click="$emit('update:dialogUsersAClassificarAli', false)">
          mdi-close
        </v-icon>
      </v-card-title>

      <v-divider></v-divider>

      <v-btn-toggle
        v-model="classificados"
        borderless
        style="width: calc(100% / 2)"
        @change="getUsers"
      >
        <v-btn :value="true" block @click="page = 1">
          <span class="font-weight-bold" >CLASSIFICADO</span>
        </v-btn>

        <v-btn :value="false" block @click="page = 1">
          <span class="font-weight-bold">SEM CLASSIFICAÇÃO</span>
        </v-btn>
      </v-btn-toggle>

      <v-divider></v-divider>

      <div class="d-flex justify-space-between w-sreen align-center">
     
            <v-btn
              class="ml-2 mt-2 white--text"
              color="xbColor"
              depressed
              :disabled="page == 1 ? true : false"
              rounded
              @click="previousPage()"
            >
              <v-icon size="30"> mdi-chevron-left </v-icon>
            </v-btn>

            <span class="mt-2">Página {{ this.page }} de {{ this.number_pages }}</span>

            <v-btn
              class="mr-2 mt-2 white--text"
              color="xbColor"
              depressed
              :disabled="page == number_pages ? true : false"
              rounded
              @click="nextPage()"
            >
              <v-icon size="30"> mdi-chevron-right </v-icon>
            </v-btn>
        </div>


      <v-card-text class="px-5 py-6">
        <v-row>
          <v-col
            v-for="(usuario, index) in usuarios"
            :key="index"
            cols="12"
            md="6"
            lg="3"
          >
            <v-card
              outlined
              color="backgroundApp"
              style="overflow: hidden"
              :loading="salvando && indexSalvando == index ? true : false"
            >
              <div class="d-flex align-center justify-start">
                <v-avatar size="100" tile>
                  <v-img v-if="usuario.avatar" :src="usuario.avatar"></v-img>
                  <v-img v-else :src="require('@/assets/avatar_placeholder.png')"></v-img>
                </v-avatar>

                <div class="d-block pa-3">
                  <div class="font-weight-bold">{{ usuario.id }}</div>
                  <div
                    class="text-subtitle-1 font-weight-bold xbColor--text text-uppercase"
                  >
                    {{ usuario.nome }}
                  </div>
                </div>
              </div>

              <v-divider></v-divider>

              <v-card
                flat
                color="backgroundApp"
                width="100%"
                height="100%"
                class="d-block"
              >
                <div class="pa-3">
                  <div class="font-weight-bold">Motivo Participação:</div>
                  {{ usuario.motivo_participacao }}

                  <v-autocomplete
                    v-model="usuario.porque_participou_id"
                    :items="porqueParticipou"
                    item-text="descricao"
                    item-value="id"
                    dense
                    hide-details
                    clearable
                    placeholder="escolha o motivo..."
                  ></v-autocomplete>
                </div>

                <v-divider class="my-3"></v-divider>

                <div class="pa-3">
                  <div class="font-weight-bold">Maior Dificuldade:</div>
                  {{ usuario.empresa_dificuldade }}

                  <v-autocomplete
                    v-model="usuario.maior_dor_id"
                    :items="maioresDores"
                    item-text="descricao"
                    item-value="id"
                    dense
                    hide-details
                    clearable
                    placeholder="escolha a maior dificuldade..."
                  ></v-autocomplete>
                </div>

                <v-divider class="my-3"></v-divider>

                <div class="pa-3">
                  <div class="font-weight-bold">Maior desejo:</div>
                  {{ usuario.desejo_empresa }}

                  <v-autocomplete
                    v-model="usuario.maior_desejo_id"
                    :items="maioresDesejos"
                    item-text="descricao"
                    item-value="id"
                    dense
                    hide-details
                    clearable
                    placeholder="escolha o maior desejo..."
                  ></v-autocomplete>
                </div>
              </v-card>

              <v-card-actions class="d-flex justify-end mt-4">
                <v-btn
                  class="white--text"
                  color="xbColor"
                  depressed
                  block
                  :disabled="
                    usuario.porque_participou_id == null ||
                    usuario.maior_dor_id == null ||
                    usuario.maior_desejo_id == null
                  "
                  @click="salvar(usuario)"
                >
                  Salvar
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import api from "@/api/axios_service";

export default {
  name: "UsersAClassificarAli",

  props: {
    dialogUsersAClassificarAli: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      dialog: false,
      loading: false,
      salvando: false,
      indexSalvando: null,
      classificados: false,
      number_pages: null,
      page: 1,
      usuarios: [],
      porqueParticipou: [],
      maioresDores: [],
      maioresDesejos: [],
    };
  },

  methods: {
    async getUsers() {
      this.loading = true;

      const filtro = `&classificados=${this.classificados}`;

      const response = await api.get(
        `/reports/users-classificar-ali?page=${this.page}` + filtro
      );
      this.number_pages = response.data.usuarios.last_page;
      this.usuarios = response.data.usuarios.data;
      this.porqueParticipou = response.data.porqueParticipou;
      this.maioresDores = response.data.maioresDores;
      this.maioresDesejos = response.data.maioresDesejos;

      this.loading = false;
    },

    async salvar(usuario, index) {
      this.salvando = true;
      this.indexSalvando = index;

      const body = {
        porque_participou_id: usuario.porque_participou_id,
        maior_dor_id: usuario.maior_dor_id,
        maior_desejo_id: usuario.maior_desejo_id,
      };

      const response = await api.post(`/admin/usuarios/${usuario.id}`, body);
      if (response.data.success) {
        this.$toast.success("Atualizado com sucesso");

        this.usuarios.splice(index, 1);
      } else {
        this.$toast.error(response.data.message);
      }

      this.salvando = false;
    },

    nextPage() {
      this.page++;
      this.getUsers();
    },

    previousPage() {
      this.page--;
      this.getUsers();
    },
  },

  mounted() {
    this.getUsers();
  },
};
</script>

<style></style>
